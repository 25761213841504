import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/seo";
import BlogImage from "../../images/blog.jpg";
import HeaderPage from '../../utils/HeaderPage';
import Parser  from 'react-html-parser'

const MedicalDevicePatents = (props) => {
  // console.log(props)
  const MEDICAL_DEVICE_PATENTS = props.data.MEDICAL_DEVICE_PATENTS.nodes;
  const canonicalUrl = `/industries/medical-device-pattents`;
  const seo = {};

  return (
    <Layout>
      <Seo title={ `Medical Device Patents ` } canonical={ canonicalUrl } seo={seo} />
      <figure className="banner-main">
        <img src={BlogImage} alt="blog" />
      </figure>
      <section className='mainSpacing blogOutrFeatured bg-white pb-0'>
          <div className="container">
            <div className="row">
                <h2 className="mb-0">
                Medical Device Patents
                </h2>
          </div>
          </div>
      </section>
      <section className='mainSpacing blogOutrFeatured bg-white'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div class="list-group">
                    {
                    MEDICAL_DEVICE_PATENTS.map((node, index) => (
                        <a href={"/blog/" + node.slug} class="list-group-item list-group-item-action bg-grey border-0 mb-4" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1 text-primary">{node.title}</h5>
                            </div>
                            {/* <p class="mb-1">{(node.seo.metaDesc)}</p> */}
                        </a>
                    ))
                    }
                    </div>
                </div>
            </div>
            <div className="mb-5"></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    MEDICAL_DEVICE_PATENTS: allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Medical Device"}}}}}
      limit: 100
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        uri
        title
        seo {
            metaDesc,
            focuskw
        }
        slug
        date(fromNow: true)
        categories {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  layout: CONSTRAINED
                  width: 230, 
                  height: 230
                )
              }
            }
          }
        }
      }
    }
  }
`

export default MedicalDevicePatents;
